import React, { useEffect, useState } from "react";
import Query from "../Query";
import { Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import mainLogo from "../../img/logo.png";

import CATEGORIES_QUERY from "../../queries/category/categories";
import { Dropdown } from "react-bootstrap";

const otherSites = [
  {
    title: "Hafencity",
    link: "https://fahrschule-hafencity.de/",
  },
  {
    title: "Geesthacht",
    link: "https://www.geesthacht-fahrschule.de/",
  },
];

function MyNav() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(window.innerWidth < 1200);

    window.addEventListener("resize", () => {
      setIsMobile(window.innerWidth < 1200);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setIsMobile(window.innerWidth < 1200);
      });
    };
  }, []);

  return (
    <Query query={CATEGORIES_QUERY} id={null}>
      {({ data: { categories } }) => {
        return (
          <Navbar expand="xl">
            <Container fluid>
              <Navbar.Brand href="/">
                <img src={mainLogo} alt="Fahrschule Bergedorf" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <span className="icon"></span>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Dropdown
                    as={Nav.Item}
                    align="start"
                    id="link-nav-dropdown"
                    className={`text-uppercase`}
                    {...(!isMobile ? { show: true } : {})}
                  >
                    <div className="d-flex justify-content-end align-items-center gap-2">
                      <Dropdown.Toggle
                        as={CustomToggle}
                        variant="none"
                        className="btn"
                      >
                        <Nav.Link href="#">Standorte</Nav.Link>
                      </Dropdown.Toggle>
                    </div>

                    <Dropdown.Menu>
                      {otherSites.map((site) => {
                        return (
                          <Dropdown.Item as="div" key={site.title}>
                            <a
                              href={site.link}
                              className="dropdown-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {site.title}
                            </a>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown
                    as={Nav.Item}
                    align="start"
                    id="basic-nav-dropdown"
                    className="text-uppercase"
                    {...(!isMobile ? { show: true } : {})}
                  >
                    <div className="d-flex justify-content-end align-items-center gap-2">
                      <Nav.Link href="/fuehrerscheine/" target="_top">
                        Führerscheine
                      </Nav.Link>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        variant="none"
                        className="btn"
                      />
                    </div>
                    <Dropdown.Menu>
                      {categories.data.slice(0, 6).map((category) => {
                        return (
                          <Dropdown.Item
                            as="div"
                            key={category.attributes.slug}
                          >
                            <Link
                              to={`/fuehrerscheine/${category.attributes.slug}`}
                              className="dropdown-link"
                              target="_top"
                              rel="noopener noreferrer"
                            >
                              {category.attributes.title}
                            </Link>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <Nav.Item>
                    <Nav.Link href="/preise">PREISE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/erste-hilfe">ERSTE HILFE</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/team">ÜBER UNS</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="/kontakt">KONTAKT</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        );
      }}
    </Query>
  );
}

export default MyNav;

function initNavbarРideWhileScrolling() {
  const nav = document.querySelector(".navbar");
  const navnotscroll = document.querySelector(".navbar-toggler");

  if (!nav) return;

  let scrollPrev = 0;
  window.addEventListener("scroll", function () {
    const scrolled = window.pageYOffset;

    const scrollDown = scrolled > 100 && scrolled > scrollPrev;

    if (navnotscroll.classList.contains("collapsed")) {
      if (scrollDown) {
        nav.classList.add("out");
      }

      if (!scrollDown && nav.classList.contains("out")) {
        nav.classList.remove("out");
      }
    }

    scrollPrev = scrolled;
  });
}

window.addEventListener("load", () => {
  setTimeout(() => {
    initNavbarРideWhileScrolling();
  }, 1000);
});

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="d-flex align-items-center gap-2"
  >
    {children}
    <span className="d-block d-xl-none myArrow">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="15"
        viewBox="0 0 26 15"
        fill="none"
      >
        <path
          d="M1 1L13 14L25 1"
          stroke="#fff"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  </div>
));
