import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Anmelden from "../Content/anmelden";

function FAQ(elements) {
  const script = document.createElement("script");

  script.type = "application/ld+json";
  script.innerHTML = `
      {
        "@context": "https://schema.org/",
        "@type": "FAQPage",
        "mainEntity": [
			${elements.elements.map((item) => {
        return `{
						"@type": "Question",
						"name": "${item.question}",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "${item.answer}"
						}
					}`;
      })}
        ]
      }
    `;

  document.body.appendChild(script);

  return (
    <>
      <Accordion defaultActiveKey="0" flush className="layout_mt_min">
        {elements.elements.map((item, index) => {
          return (
            <Accordion.Item eventKey={item.id} key={item.id} data-aos="fade-up">
              <Accordion.Header>
                <Row className="w-100">
                  <Col xs={11} md={10} lg={8} xl={6} xxl={10}>
                    {index + 1}. {item.question}
                  </Col>
                </Row>
              </Accordion.Header>
              <Accordion.Body>
                <Row>
                  <Col xs={12} md={11} lg={10}>
                    {item.answer}
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <div className="layout_mt_min d-flex justify-content-end">
        <Anmelden
          btn="btn btn__big btn__border btn__border_primary"
          newform={elements.newform}
        ></Anmelden>
      </div>
    </>
  );
}

export default FAQ;
