import React from "react";
import Query from "../../components/Query";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import UBERUNS_QUERY from "../../queries/ue-ber-uns";

import Review from "../../components/Review";
import Team from "../../components/Content/team";
import Gallery from "../../components/Content/gallery";
import StandorteBlock from "../../components/Content/standorte";

// import {Helmet} from "react-helmet-async";

const Uber_uns = () => {
  return (
    <Query query={UBERUNS_QUERY}>
      {({ data: { aboutUs, contact } }) => {
        const about = aboutUs.data.attributes;
        const kontact = contact.data.attributes;

        return (
          <>
            {/* <Helmet>
              <title>{about.seo[0].metaTitle}</title>
              <meta name="description" content={about.seo[0].metaDescription} />
              <meta name="keywords" content={about.seo[0].keywords} />
              <link rel="canonical" href={window.location.href} />
              <meta property="og:title" content={about.seo[0].metaTitle} />
              <meta property="og:description" content={about.seo[0].metaDescription} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:image" content={process.env.REACT_APP_BACKEND_URL + about.seo[0].metaImage.data.attributes.url} />
            </Helmet> */}
            <header className="layout layout__min layout__bg layout__bg_primary">
              <Container fluid>
                <h1
                  data-aos="fade-up"
                  className="layout_title layout_title__light layout_mb"
                >
                  {about.title}
                </h1>
                <Row className="align-items-center">
                  <Col xs={12} md={10} lg={7} xl={5}>
                    <img
                      data-aos="fade-up"
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        about.thumb.data.attributes.url
                      }
                      alt={about.title}
                      className="autoImg layout_image layout_image__round"
                    />
                  </Col>
                  <Col xs={12} md={10} lg={5} xl={6} className="offset-xl-1">
                    <p
                      data-aos="fade-up"
                      className="layout_text"
                      dangerouslySetInnerHTML={{ __html: about.description }}
                    ></p>
                  </Col>
                  <span className="layout_mt_min"></span>
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={10}
                    lg={8}
                    xl={5}
                    xxl={4}
                    className="layout_mb__adaptive"
                  >
                    <p
                      data-aos="fade-up"
                      className="layout_text layout_mb__30"
                      dangerouslySetInnerHTML={{ __html: about.helpdescr }}
                    ></p>
                    <p
                      data-aos="fade-up"
                      className="layout_text layout_text__link layout_text__light d-inline-block"
                      dangerouslySetInnerHTML={{ __html: kontact.helptime }}
                    ></p>
                    {console.log(kontact.helptime)}
                  </Col>
                  <Col
                    data-aos="fade-up"
                    xs={12}
                    md={10}
                    xl={5}
                    className="offset-xl-2 layout_mb__adaptive"
                  >
                    <p
                      data-aos="fade-up"
                      className="layout_text layout_mb__30"
                      dangerouslySetInnerHTML={{ __html: about.emaildescr }}
                    ></p>
                    <a
                      data-aos="fade-up"
                      href={`mailto:${kontact.email}`}
                      className="layout_text layout_text__link layout_text__light"
                    >
                      {kontact.email}
                    </a>
                  </Col>
                  <Col
                    xs={12}
                    md={10}
                    xl={5}
                    className="offset-xl-7 offset-xxl-6 layout_mb__adaptive"
                  >
                    <p data-aos="fade-up" className="h3">
                      {about.thxtitle}
                    </p>
                  </Col>
                </Row>
              </Container>
            </header>
            <Team teams={about.team}></Team>
            <Review></Review>
            <Gallery items={about.slider}></Gallery>
            <StandorteBlock
              title={"Weitere<br/>Standorte"}
              description={
                "Besuchen Sie unsere Fahrschulen in Bergedorf, HafenCity und Geesthacht! Genießen Sie hochwertigen Fahrunterricht und professionelle Unterstützung an jedem Standort."
              }
            />
          </>
        );
      }}
    </Query>
  );
};

export default Uber_uns;
