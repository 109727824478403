import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

export class FormUserDetails extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  

  render() {
    return (
      <>
        <h3 className="h1 layout_mb" id="contained-modal-title-vcenter">
            VORANMELDUNG
        </h3>
        <div className="layout_text layout_mb__30">
            <p>
                Mit dem folgenden Formular kannst du uns vorab Deine persönlichen Daten und Informationen senden. Nach Eingang Deiner Daten melden wir uns per E-Mail mit näheren Infos.
            </p>
            <p>
                Es handelt sich hierbei lediglich um eine Voranmeldung. Die endgültige Anmeldung würde vor Ort stattfinden und dauert dank Deiner Voranmeldung lediglich einige wenige Augenblicke.
            </p>
        </div>
        <div className='d-flex'>
          <Button
            color="primary"
            variant="contained"
            onClick={this.continue}
            className="btn__fill btn__fill_primary btn__fill_primary_static ms-auto"
          >NÄCHSTE -&gt;</Button>
        </div>
      </>
    );
  }
}

export default FormUserDetails;