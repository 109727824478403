import gql from "graphql-tag";

const UBERUNS_QUERY = gql`
  query AboutUs {
    aboutUs {
      data {
        attributes {
          slug
          title
          description
          thumb {
            data {
              attributes {
                url
              }
            }
          }
          helpdescr
          emaildescr
          email
          thxtitle
          seo {
            metaTitle
            metaDescription
            metaImage {
              data {
                attributes {
                  url
                }
              }
            }
            metaSocial {
              title
            }
            keywords
            metaRobots
            metaViewport
            canonicalURL
          }
          team {
            person {
              name
              position
              photo {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
          slider {
            title
            slide {
              thumb {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    contact {
      data {
        attributes {
          email
          helptime
        }
      }
    }
  }
`;

export default UBERUNS_QUERY;
