import Container from 'react-bootstrap/Container';

// import {Helmet} from "react-helmet-async";
import Query from '../../components/Query';
import POLICY_QUERY from '../../queries/policy';

import ReactMarkdown from "react-markdown";

const Policy = () => {

    return(
        <Query query={POLICY_QUERY}>
            {({ data: { policy } }) => {
                const elem = policy.data.attributes;
                return(
                    <>
                        {/* <Helmet>
                            <title>Datenschutz - Fahrschule-Bergedorf</title>
                            <meta name="description" content="Einmal im Monat findet der Intensivkurs statt. In 7 Terminen bekommst du alle notwendigen Lektionen und bist bereit für die Theorie-Prüfung." />
                            <meta name="keywords" content="fahrschule bergedorf, bergedorf fahrschule, auto fahren lernen, fahren lernen, führerschein theorie, intensivfahrschule, intensiv fahrschule, fahrschule intensivkurs, theorieprüfung, b197 führerschein, führerschein ab 17, automatik führerschein, BE führerschein"/>
                            <link rel="canonical" href={window.location.href} />
                            <meta property="og:title" content="Datenschutz - Fahrschule-Bergedorf" />
                            <meta property="og:description" content="Einmal im Monat findet der Intensivkurs statt. In 7 Terminen bekommst du alle notwendigen Lektionen und bist bereit für die Theorie-Prüfung." />
                            <meta property="og:type" content="website" />
                            <meta property="og:url" content={window.location.href} />
                        </Helmet> */}
                        <main className="layout layout__color_primary">
                            <Container fluid>
                                <h1 data-aos="fade-up" className='layout_mb'>{elem.title}</h1>
                                <div className='layout_text layout_text__special'>
                                    <ReactMarkdown children={elem.content} />
                                </div>
                            </Container>
                        </main> 
                    </>
                )
            }}
        </Query>
        
    )
}

export default Policy;