import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Anmelden from "../Content/anmelden";

function Header(props) {
  let element = props.props;
  if (element.categoryname === "B" || element.categoryname === "BE") {
    return (
      <Row>
        <Col xs={10} md={9} xl={10}>
          <h1 data-aos="fade-up" className="layout_title layout_title__h1">
            {props.props.title}
          </h1>
        </Col>
        <Col>
          <span data-aos="fade-left" className="layout_plug">
            {props.props.categoryname}
          </span>
        </Col>
        <span className="layout_mt_min"></span>
        <Col
          xs={12}
          md={8}
          lg={7}
          xl={6}
          xxl={5}
          className="order-1 order-lg-0"
        >
          <p
            data-aos="fade-up"
            className="layout_text layout_text__xl"
            dangerouslySetInnerHTML={{ __html: props.props.description }}
          ></p>
          <Anmelden btn="btn btn__fill btn__fill_primary layout_mt_min" />
        </Col>
        <Col xs={12} md={8} lg={5} xl={5} className="offset-xl-1 offset-xxl-2">
          <img
            data-aos="fade-up"
            src={
              process.env.REACT_APP_BACKEND_URL +
              props.props.thumb.data.attributes.url
            }
            alt={props.props.title}
            className="autoImg layout_image layout_image__round"
          />
        </Col>
      </Row>
    );
  } else if (element.categoryname === "UMSCHREIBUNG") {
    return (
      <Row>
        <Col xs={10} md={9} lg={7} xl={5}>
          <Row>
            <Col xs={11} xxl={10}>
              <h1
                data-aos="fade-up"
                className="layout_title layout_title__h1"
                dangerouslySetInnerHTML={{ __html: props.props.title }}
              ></h1>
            </Col>
            <span className="layout_mt_min"></span>
            <Col xs={12} className="d-none d-lg-block">
              <p
                data-aos="fade-up"
                className="layout_text layout_text__xl"
                dangerouslySetInnerHTML={{ __html: props.props.description }}
              ></p>
              <Anmelden btn="btn btn__fill btn__fill_primary layout_mt_min" />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={10} md={8} lg={5} xl={4} className="offset-xl-3">
          <img
            data-aos="fade-up"
            src={
              process.env.REACT_APP_BACKEND_URL +
              props.props.thumb.data.attributes.url
            }
            alt={props.props.title}
            className="autoImg layout_image layout_image__round"
          />
        </Col>
        <Col
          xs={12}
          md={8}
          lg={7}
          xl={6}
          xxl={5}
          className="order-1 order-lg-0 d-lg-none"
        >
          <p
            data-aos="fade-up"
            className="layout_text layout_text__xl"
            dangerouslySetInnerHTML={{ __html: props.props.description }}
          ></p>
          <Anmelden btn="btn btn__fill btn__fill_primary layout_mt_min" />
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <span data-aos="fade-left" className="layout_plug">
          {props.props.categoryname}
        </span>
        <Col xs={10} md={9} lg={6} xl={6}>
          <Row>
            <Col xs={11} xxl={10}>
              <h1 data-aos="fade-up" className="layout_title layout_title__h1">
                {props.props.title}
              </h1>
            </Col>
            <span className="layout_mt_min"></span>
            <Col xs={12} className="d-none d-lg-block">
              <p
                data-aos="fade-up"
                className="layout_text layout_text__xl"
                dangerouslySetInnerHTML={{ __html: props.props.description }}
              ></p>
              <Anmelden
                btn="btn btn__fill btn__fill_primary layout_mt_min"
                newform={props.newform}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={10} md={8} lg={4} xl={3} className="offset-xl-1">
          <img
            data-aos="fade-up"
            src={
              props.props.thumb
                ? process.env.REACT_APP_BACKEND_URL +
                  props.props.thumb.data.attributes.url
                : props.props.image
            }
            alt={props.props.title}
            className="autoImg layout_image layout_image__round"
          />
        </Col>
        <Col
          xs={12}
          md={8}
          lg={7}
          xl={6}
          xxl={5}
          className="order-1 order-lg-0 d-lg-none"
        >
          <p
            data-aos="fade-up"
            className="layout_text layout_text__xl"
            dangerouslySetInnerHTML={{ __html: props.props.description }}
          ></p>
          <Anmelden
            btn="btn btn__fill btn__fill_primary layout_mt_min"
            newform={props.newform}
          />
        </Col>
      </Row>
    );
  }
}

export default Header;
