import axios from "axios";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export default function ContactForm() {
  const [textareaHeight, setTextareaHeight] = useState("auto");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/api/email/`,
      data: {
        to: "kontakt@fahrschule-bergedorf.de",
        from: "kontakt@fahrschule-bergedorf.de",
        subject: `${"BE: Kontakt Page - Formular"}`,
        text: `
                    <p>Vorname: ${data.Vorname}</p>
                    <p>Nachname: ${data.Nachname}</p>
                    <p>Telefonnummer: ${data.Telefonnummer}</p>
                    <p>Email: ${data.Email}</p>
                    <p>Nachricht: ${data.Nachricht}</p>
                    `,
      },
    });

    reset();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="contactForm">
      <Row className="layout_mb__30">
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Vorname">
            <Form.Control
              id="Vorname"
              type="text"
              placeholder="Vorname"
              {...register("Vorname", { required: true, maxLength: 15 })}
            />
          </FloatingLabel>
          {errors.Vorname && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Nachname">
            <Form.Control
              id="Nachname"
              type="text"
              placeholder="Nachname"
              {...register("Nachname", { required: true })}
            />
          </FloatingLabel>
          {errors.Nachname && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Telefonnummer">
            <Form.Control
              id="Telefonnummer"
              type="phone"
              placeholder="Telefonnummer"
              {...register("Telefonnummer", {
                required: true,
                pattern: `/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/`,
              })}
            />
          </FloatingLabel>
          {errors.Telefonnummer && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="E-Mail Adresse">
            <Form.Control
              id="Email"
              type="email"
              placeholder="E-Mail Adresse"
              {...register("Email", {
                required: true,
                pattern: `/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/`,
              })}
            />
          </FloatingLabel>
          {errors.Email && <p>Bitte das Feld ausfüllen</p>}
        </Col>
        <Col xs={12} className="layout_mb__30">
          <FloatingLabel label="Nachricht">
            <Form.Control
              id="Nachricht"
              as="textarea"
              rows={3}
              placeholder="Nachricht"
              {...register("Nachricht", { required: true, maxLength: 500 })}
              style={{ minHeight: "100px", height: textareaHeight }}
              maxLength={500}
              onChange={(e) => setTextareaHeight(`${e.target.scrollHeight}px`)}
            />
          </FloatingLabel>
          {errors.Nachricht && (
            <p>
              {errors.Nachricht.type === "maxLength"
                ? "Nachricht ist zu lang"
                : "Bitte das Feld ausfüllen"}
            </p>
          )}
        </Col>
        <Col xs={12} className="">
          <input
            id="Datenschutz"
            type="checkbox"
            {...register("Datenschutz", { required: true })}
            className="form-check-input"
          />
          <label htmlFor="Datenschutz" className="form-check-label">
            Ich akzeptiere die{" "}
            <Link className="text-white" to={"/datenschutz"} target="_blank">
              Datenschutzerklärung.
            </Link>
          </label>
          {errors.Datenschutz && <p>Bitte das Feld ausfüllen</p>}
        </Col>
      </Row>
      <div className="d-flex">
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className="btn__fill btn__fill_primary btn__fill_primary_static ms-auto"
        >
          SENDEN
        </Button>
      </div>
    </Form>
  );
}
