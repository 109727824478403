import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Content(props){
	let element = props.obj;
	function NoPhoto(){
		return(
			<Row>
				<Col md={12} lg={7} xl={6}>
					<h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.title}}></h2>
				</Col>
				<Col xs={12} md={10} lg={5} xl={5} xxl={6} className="offset-xl-1 offset-xxl-0 layout_mt__md mt-lg-0">
					<p data-aos="fade-up" className='layout_text' dangerouslySetInnerHTML={{__html: element.description}}></p>
				</Col>
			</Row>
		)
	}

	function MainOne(){
		return(
			<Row className={`${props.catname === "BF17" ? "layout_BF17 ": "" }`}>
				<Col md={12} lg={7} xl={6}>
					<Row>
						<Col xs={12} xl={7} xxl={8} className="mb-lg-5 mb-xl-0"><h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.title}}></h2></Col>
						<Col xs={10} sm={8} md={6} xl={5} xxl={4} className={`mt-xl-0 ${props.catname === "BF17" ? "special_mt": "layout_mt__md " }`}><img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.thumb.data.attributes.url} alt={element.title} className={`autoImg layout_image layout_image__round ${props.catname === "BF17" ? "layout_image__absolute ": "" }`}/></Col>
					</Row>
				</Col>
				<Col xs={12} md={10} lg={5} xl={5} xxl={4} className="offset-xl-1">
					<p className='layout_text' data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.description}}></p>
				</Col>
			</Row>
		)
	}

	function MainTwo(){
		return(
			<Row>
				<Col md={12} lg={7} xl={6}>
					<Row className='h-100 flex-column justify-content-between'>
						<Col xs={12} xl={7} xxl={8} className="mb-lg-5 mb-xl-0"><h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.title}}></h2></Col>
						<Col lg={8} xl={5} className='mt-xl-0 d-none d-lg-block'><img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.thumb.data.attributes.url} alt={element.title} className={`autoImg layout_image layout_image__round ${props.catname === "BF17" ? "layout_image__absolute ": "" }`}/></Col>
					</Row>
				</Col>
				<Col xs={12} md={10} lg={5} xl={5} xxl={4} className="offset-xl-1 layout_mt__md">
					<p className='layout_text' data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.description}}></p>
				</Col>
				<Col xs={12} sm={10} md={8} className='layout_mt__md d-lg-none'><img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.thumb.data.attributes.url} alt={element.title} className={`autoImg layout_image layout_image__round ${props.catname === "BF17" ? "layout_image__absolute ": "" }`}/></Col>
			</Row>
		)
	}

	function MainThree(){
		return(
			<Row>
				<Col md={12} lg={7} xl={6}>
					<Row className='h-100 flex-column'>
						<Col xs={12} xl={7} xxl={8} className="mb-lg-5 mb-xl-0"><h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.title}}></h2></Col>
						<Col xs={12} sm={10} md={8} lg={8} xl={7} className='layout_mt__md layout_mt'><img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.thumb.data.attributes.url} alt={element.title} className={`autoImg layout_image layout_image__round`}/></Col>
					</Row>
				</Col>
				<Col xs={12} md={10} lg={5} xl={6} xxl={6}>
					<ol className='list list__small'>
						{element.list.map((item, index) => {
							return(
								<li data-aos="fade-up" className='list_item d-flex align-items-start' key={`list_${index}`}><span className='layout_text'>{item.description}</span></li>
							)
						})}
					</ol>
				</Col>
			</Row>
		)
	}

	function B197(){
		return(
			<Row>
				<Col md={12} lg={7} xl={6}>
					<Row>
						<Col xs={12} xl={7} xxl={8}><h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.title + `
							<svg class="contentSvg" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 78 78">
								<defs>
								<clipPath id="clip-thicker">
									<rect width="78" height="78"/>
								</clipPath>
								</defs>
								<g id="thicker" clip-path="url(#clip-thicker)">
								<path id="_" data-name="*" d="M37.5-39.864h-10l.479-20.344L10.588-49.571,5.648-58.25l17.93-9.705L5.648-77.66l4.94-8.678L27.981-75.7,27.5-96.045h10L37.019-75.7,54.412-86.338l4.94,8.678-17.93,9.705,17.93,9.705-4.94,8.678L37.019-60.208Zm-6.925-3h3.853L33.89-65.637,53.336-53.746l1.892-3.325L35.119-67.955,55.229-78.839l-1.892-3.325L33.89-70.272l.536-22.774H30.573l.536,22.774L11.664-82.164,9.771-78.839,29.881-67.955,9.771-57.07l1.892,3.325L31.11-65.637Z" transform="translate(6.352 107.045)" fill="#00664e"/>
								</g>
							</svg>
						`}}></h2></Col>
					</Row>
				</Col>
				<Col xs={12} md={10} lg={5} xl={5} xxl={4} className="offset-xl-1 order-1 order-lg-0">
					<p data-aos="fade-up" className='layout_text' dangerouslySetInnerHTML={{__html: element.description}}></p>
				</Col>
				<Col xs={10} sm={8} md={6} xl={5} xxl={3} className={`layout_mt layout_mt__md`}>
					<img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.thumb.data.attributes.url} alt={element.title} className={`autoImg layout_image layout_image__round ${props.catname === "BF17" ? "layout_image__absolute ": "" }`}/>
				</Col>
				<Col xs={12} md={10} lg={5} xl={5} className="offset-lg-1 offset-xl-2 offset-xxl-4 layout_mt order-1">
					<ol className='list'>
						{element.list.map((item, index) => {
							return(
								<li data-aos="fade-up" className='list_item d-flex align-items-start' key={`list_${index}`}><span className='layout_text'>{item.description}</span></li>
							)
						})}
					</ol>
				</Col>
				<Col xs={12} md={10} lg={5} xl={5}className="order-1 pe-xl-5 layout_mt mt-lg-0">
					<p data-aos="fade-up" className='layout_text' dangerouslySetInnerHTML={{__html: `
						<svg class="contentSvg_min" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 78 78">
							<defs>
							<clipPath id="clip-thicker">
								<rect width="78" height="78"/>
							</clipPath>
							</defs>
							<g id="thicker" clip-path="url(#clip-thicker)">
							<path id="_" data-name="*" d="M37.5-39.864h-10l.479-20.344L10.588-49.571,5.648-58.25l17.93-9.705L5.648-77.66l4.94-8.678L27.981-75.7,27.5-96.045h10L37.019-75.7,54.412-86.338l4.94,8.678-17.93,9.705,17.93,9.705-4.94,8.678L37.019-60.208Zm-6.925-3h3.853L33.89-65.637,53.336-53.746l1.892-3.325L35.119-67.955,55.229-78.839l-1.892-3.325L33.89-70.272l.536-22.774H30.573l.536,22.774L11.664-82.164,9.771-78.839,29.881-67.955,9.771-57.07l1.892,3.325L31.11-65.637Z" transform="translate(6.352 107.045)" fill="#00664e"/>
							</g>
						</svg>
					` + element.info.description}}></p>
				</Col>
			</Row>
		)
	}

	function ContentOne(){
		return(
			<Row className={`${ props.catname === "BE" ? "layout_mt__100_lg" : 'layout_mt__100 pt-5'}`}>
				{ props.catname === "BE" ? (
						<Col md={12} lg={6} xl={5} className="mb-5 mb-xl-0 offset-lg-1 offset-xl-2  order-1 d-none d-lg-block">
							<img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.info.thumb.data.attributes.url} alt={element.title} className="autoImg layout_image layout_image__round"/>
						</Col>
					) : (
						<Col md={12} lg={7} xl={6} className="mb-5 mb-xl-0">
							<Row className='h-100 flex-column justify-content-between'>
								<Col xs={12} xl={7} xxl={8}><h2 data-aos="fade-up" dangerouslySetInnerHTML={{__html: element.info.title}}></h2></Col>
								<Col xs={12} sm={10} md={8} className='layout_mt mt-xl-0'><img data-aos="fade-up" src={process.env.REACT_APP_BACKEND_URL + element.info.thumb.data.attributes.url} alt={element.title} className="autoImg layout_image layout_image__round"/></Col>
							</Row>
						</Col>
					)}
				
				<Col xs={12} md={10} lg={5} xl={5} className={ props.catname === "BE" ? "" : 'offset-xl-1'}>
				{ props.catname === "BE" ? (
						<p data-aos="fade-up" className='layout_text layout_mb__adaptive' dangerouslySetInnerHTML={{__html: element.info.description}}></p>
					) : 
					""
				}
					<ol className={`list ${ props.catname === "BE" ? "list__small" : ''}`}>
						{element.list.map((item, index) => {
							return(
								<li data-aos="fade-up" className={`list_item d-flex ${ props.catname === "BE" ? "align-items-center" : "align-items-start"}`} key={`list_${index}`}><span className='layout_text'>{item.description}</span></li>
							)
						})}
					</ol>
				</Col>
			</Row>
		)
	}

	function ContentFooter(){
		return(
			<Row className='layout_mt__100_lg'>
				<Col md={12} lg={7} xl={5} className="layout_mb__30 mb-lg-0">
					<p data-aos="fade-up" className='layout_text' dangerouslySetInnerHTML={{__html: element.description1}}></p>
				</Col>
				{ element.description2 !== null ? 
					<Col xs={12} md={10} lg={5} xxl={4} className="offset-xl-2">
						<p data-aos="fade-up" className='layout_text' dangerouslySetInnerHTML={{__html: element.description2}}></p>
					</Col>
					:
					''
				}
				
				<Col xs={12} lg={5} className={`text-end text-lg-start ${ element.description2 !== null ? 'offset-lg-7 offset-xl-7' : 'offset-xl-2'} `}>
					<a data-aos="fade-up" href='/kontakt' className={`btn btn__border btn__border_primary btn__mobile mt-5 ${ element.description2 !== null ? 'mt-lg-3' : 'mt-lg-0'}`}>KONTAKT</a>
				</Col>
			</Row>
		)
	}

	
	if(element.thumb.data === null){
		return(
			<NoPhoto></NoPhoto>
		)
	} else if(element.thumb.data !== null && element.info === null){
		return(
			<>
				{ props.catname === "UMSCHREIBUNG" ?(
					<>
						<MainThree></MainThree>
						<ContentFooter></ContentFooter>
					</>
				) : (
					<MainOne></MainOne>
				)}
			</>
		)
	} else if(props.catname === "B197"){
		return(
			<B197></B197>
		)
	} else if(element.thumb.data !== null && element.info !== null && element.list !== null && element.info.thumb.data !== null){
		return(
			<>
				{ props.catname === "BE" ? (
					<MainTwo></MainTwo>
					) : (
						<MainOne></MainOne>
					)
				}
				<ContentOne></ContentOne>
				{ props.catname === "BE" ? (
					<ContentFooter></ContentFooter>
					) : ""
				}
			</>
		)
	}
}

export default Content;