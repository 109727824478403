import React from "react";
import Query from "../../components/Query";
import HOME_QUERY from "../../queries/home";
import Push from "../../components/Push";
import StandorteBlock from "../../components/Content/standorte";
import TeamBlock from "../../components/Home/team";
import LeistungenBlock from "../../components/Home/leistungen";
import FAQBlock from "../../components/Home/faq";
import ReferalBlock from "../../components/Home/referal";
import IntensivekursBlock from "../../components/Home/intensivekurs";
import HomeHeader from "../../components/Header";

const Home = () => {
  return (
    <Query query={HOME_QUERY} id={null}>
      {({ data: { home } }) => {
        return (
          <>
            <HomeHeader data={home.data.attributes.headerblock} />
            <IntensivekursBlock />
            <ReferalBlock
              title={home.data.attributes.referal.title}
              desc={home.data.attributes.referal.descr}
              img={home.data.attributes.referal.image.data.attributes}
            />
            <LeistungenBlock />
            <TeamBlock />
            <StandorteBlock
              title={"Weitere<br/>Standorte"}
              description={
                "Besuchen Sie unsere Fahrschulen in Bergedorf, HafenCity und Geesthacht! Genießen Sie hochwertigen Fahrunterricht und professionelle Unterstützung an jedem Standort."
              }
            />
            <FAQBlock />
            <Push></Push>
          </>
        );
      }}
    </Query>
  );
};

export default Home;
